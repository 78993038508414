import React, { useEffect } from "react"
import Layout from "../components/Layout/Layout"
import Breadcrumbs from "../components/Breadcrumbs/Breadcrumbs"
import HeaderImage from "../assets/img/new/tlo-serch-01.webp"
import HubSpotForm from "../components/HubSpotForm"
import { devMode } from "../components/Variables"

import "../../i18n"
import { useTranslation } from "react-i18next"
import i18n from "i18next"

const SeoConsultation = () => {
  const { t } = useTranslation()
  useEffect(() => {
    i18n.changeLanguage("pl")
  }, [])

  return (
    <Layout
      seo={{
        title: "Bezpłatna konsultacja SEO • Wypełnij formularz • WeNet",
        description:
          "Skorzystaj z darmowej konsultacji SEO i podnieś pozycję swojej strony w Google •  Wypełnij formularz zgłoszeniowy",
        hreflangs: {
          pl: "bezplatna-konsultacja-seo/",
          en: "en/free-seo-consultation/",
          de: "de/kostenlose-seo-beratung/",
        },
        schema: {
          "@context": "https://schema.org",
          "@type": "BreadcrumbList",
          itemListElement: [
            {
              "@type": "ListItem",
              position: 1,
              item: {
                "@id": "/",
                name: "Główna",
              },
            },
            {
              "@type": "ListItem",
              position: 2,
              item: {
                "@id": "/bezplatna-konsultacja-seo/",
                name: t("seo-title-contact"),
              },
            },
          ],
        },
      }}
    >
      <div className="hero-header-page" style={{ backgroundImage: `url("${HeaderImage}")` }}>
        <div className="breadcrumbs-wrapper">
          <Breadcrumbs
            path={[
              { url: t("link-url-home"), text: t("link-text-home") },
              {
                url: t("link-url-free-seo-consultation"),
                text: t("link-text-free-seo-consultation"),
              },
            ]}
          />
        </div>
        <h1 className="text-center">Bezpłatna konsultacja SEO</h1>
      </div>

      <section className="standard-section contact-section">
        <div className="container">
          <div className="row">
            <div className="col-xl-10 offset-xl-1 col-md-10 offset-md-1 offset-lg-0 col-12">
              <p className="text-blue" style={{ marginTop: "30px", fontSize: "17px" }}>
                Chcesz poprawić błędy na swojej stronie oraz podnieść pozycję w wynikach Google? Wypełnij formularz.
                Nasz konsultant skontaktuje się z Tobą i przeprowadzi audyt Twojej strony za darmo.
                <br />
                <br />
              </p>
            </div>
            <div className="col-xl-5 offset-xl-1 col-lg-6 col-md-10 offset-md-1 offset-lg-0">
              <h2>Formularz zgłoszeniowy</h2>
              <HubSpotForm
                formId={
                  devMode === "true" ? "84bb02f1-a650-46bd-8e81-82120694ef5e" : "ff41c34a-8787-42d1-9603-eb1575a49bf3"
                }
                formType="consultation"
              />
            </div>
            <div className="col-xl-4 offset-xl-1 col-lg-6 col-md-10 offset-md-1 offset-lg-0">
              <p className="text-blue font-18" style={{ marginTop: "33px" }}>
                Dzięki przeprowadzeniu konsultacji SEO zyskujesz wytyczne do optymalizacji, a wprowadzenie poprawek
                spowoduje:
                <br />
                <br />
              </p>
              <ul style={{ marginBottom: "45px" }}>
                <li>
                  dobrze zoptymalizowany serwis, który nie traci na starcie – w niektórych przypadkach błędy mogą
                  całkowicie eliminować stronę z gry,
                </li>
                <li>
                  oszczędność czasu i pieniędzy – poprawny, jakościowy serwis to gwarancja maksymalnego obniżenia
                  kosztów późniejszych działań SEO i SEM,
                </li>
                <li>
                  wyższą pozycję serwisu i zwiększenie ruchu – zoptymalizowana strona WWW to szybsze indeksowanie przez
                  roboty Google,
                </li>
                <li>
                  skuteczny dostęp do użytkowników, czyli potencjalnych klientów – na wartościowej witrynie zyskują
                  także użytkownicy, którzy docierają szybciej i skuteczniej do informacji, których szukali.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default SeoConsultation
